/*******************************************************************************
 * Search Section
 */

.search-section {
  color: #FFF;
  background: #0084bb;
  padding-top: 30px;
  padding-bottom: 30px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.41);

  p {
    font-size: 20px;
  }

  .search-title {
    display: block;
    font-size: 50px;
    max-width: none;
  }

  .search-form {
    display: block;
    width: 100%;
    margin-top: 1em;

    .input-group {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    button {
      display: inline-block;
      float: right;
    }
  }

  .input-field-group {
    color: #000;
  }

  .search-form-index button {
    margin-top: 15px;
  }

  .tablet-image {
    position: relative;
    min-height: 442px;
    pointer-events: none;
  }

  .tablet-image img {
    position: absolute;
    top: 0;
  }

}

@media(min-width: 768px) {
  /* Small Devices */
  .search-section {
    .search-section-inner {
      /**
       * Using flex to make the search box look nice with long title names. Even without the support it looks decent,
       * the VIN search just isn't vertically centered and instead goes to the top.
       */
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .search-form {
      display: inline-block;
      float: right;
      margin-top: 0;
      max-width: 388px;
      vertical-align: middle;
    }

    .search-title {
      display: inline-block;
      max-width: 330px;
    }
  }
}

@media(min-width: 992px) {
  /* Medium devices */
  .search-section {
    .search-form {
      max-width: 432px;
    }

    .search-title {
      max-width: none;
    }
  }
}

@media(min-width: 1200px) {
  /* Large devices */
  .search-section {
    .search-form-index {
      margin-top: 50px;
    }
  }
}
