/**
* Navbar
*/
.navbar-container {
  padding: 0;
}

.navbar {
  background-color: #FFF;
  border-width: 0;
  font-size: 17px;
  margin-bottom: 0;
}

.navbar-header {
  padding-top: 5px;

  .logo-container {
    display: inline-block;
    line-height: 0;
    height: 61px;
  }

  .logo {
    height: 100%;
    width: 100%;

    img {
      display: block;
      max-height: 100%;
      width: auto;
    }
  }

  button {
    margin-top: 13px;
  }
}

.navbar-default {
  .navbar-nav {

    > li {
      a {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 71px;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      > a {
        &:active,
        &:link,
        &:hover,
        &:focus,
        &:visited {
          // High depth/precedence to overwrite bootstrap style
          color: #000;
        }
      }
    }
  }

  .navbar-menubuilder[aria-expanded="true"] .navbar-nav > li {
    border-top: 1px solid #444;

    > a {
      &:active,
      &:link,
      &:hover,
      &:focus,
      &:visited {
        // High depth/precedence to overwrite bootstrap style
        color: #fff;
      }
    }
  }

  .navbar-toggle {
    border-color: #0084BB;

    &:focus,
    &:hover,
    &.nav-shown,
    .icon-bar {
      background-color: #0084BB;
    }

    &.nav-shown .icon-bar,
    &:focus .icon-bar,
    &:hover .icon-bar {
      background-color: #FFF;
    }
  }
}


.navbar-menubuilder.collapsing,
.navbar-menubuilder[aria-expanded="true"] {
  padding: 0;
}
.collapsing .navbar-nav,
.navbar-menubuilder[aria-expanded="true"] .navbar-nav {
  padding: 0;
  margin: 0;
  width: 100%;
}
.collapsing .navbar-nav li,
.navbar-menubuilder[aria-expanded="true"] li {
  width: 100%;
  background-color: #222;
  color: #FFF;
}
.collapsing .navbar-nav li:hover,
.navbar-menubuilder[aria-expanded="true"] li:hover {
  background-color: #171717;
}
.collapsing .nav.navbar-nav a,
.collapsing .nav.navbar-nav a:visited,
.navbar-menubuilder[aria-expanded="true"] .navbar-nav a,
.navbar-menubuilder[aria-expanded="true"] .navbar-nav a:visited {
  color: #FFF;
}

/* Resizing logo and logo links correctly */
@media(min-width: 992px) {
  /* Large */
  .navbar {
    font-size: 20px;
  }
  .navbar-header .logo-container {
    height: 81px;
  }
  .navbar-nav > li > a {
    line-height: 93px;
  }
}

.inpage-nav {
  .inpage-nav-links h4 {
    margin-bottom: 1em;
  }

  .inpage-nav-section {
    margin-bottom: 4em;
  }
}
