/**
 * Record Search application page
 */

@import "../vars";

.tc-header {
  margin-bottom: 25px;
  color: @tc-blue;
  font-weight: bold;
}

.tc-prompt {
  margin-bottom: 25px;
}
