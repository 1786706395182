/*******************************************************************************
 * Info Bubbles
 */

.info-bubble-container .flex-row-item {
  max-width: 330px;
}
.info-bubble-container .info-bubble {
  display: inline-block;
  position: relative;
  margin: 50px 20px;
  padding: 30px;
  background: #E0E0E0;
  border-radius: 20px;
  text-align: left;
  white-space: normal;
}
.info-bubble-container .info-bubble *:first-child {
  margin-top: 0;
}
.info-bubble-container .info-bubble *:last-child {
  margin-bottom: 0;
}
.info-bubble-container .info-bubble:after {
  position: absolute;
  bottom: -20px;
  left: 43%;
  content: '';
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #E0E0E0;
}
.info-bubble-container .info-bubble-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}