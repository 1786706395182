/**
 * Text utilites
 */
.text-blue {
  color: #0084bb;
}
.text-gray {
  color: gray;
}
.text-header {
  margin-top: 1.5em;
  margin-bottom: .5em;
}
.text-note {
  float: right;
  padding-right: 5px;
  font-style: italic;
  font-size: 85%;
  color: #444444;
}

.in-text-link:link {
  text-decoration: none;
  color: #0084bb
}
.in-text-link:visited {
  text-decoration: none;
  color: #0084bb
}
.in-text-link:hover {
  text-decoration: underline;
}
.in-text-link:active {
  text-decoration: underline;
}

ul.list-blue {
  list-style-type: square;
}
ul.list-blue > li {
  color: #0084bb;
}
ul.list-blue > li > span {
  color: black;
}

@media(min-width: 992px) {
  h1.large {
    font-size: 50px;
  }
}