/*******************************************************************************
 * reports.html
 */

.reports-section-samples .blue-circle {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
  width: 212px;
  height: 212px;
  text-align: center;
  border: 1px solid #459EDA;
  border-radius: 50%;

  background-color: #1081b6;
  -moz-box-shadow: inset 0 0 0 -50px #dcecfb;
  -webkit-box-shadow: inset 0 0 0 -50px #dcecfb;
  box-shadow: inset 0 0 0 -50px #dcecfb;
  background: -webkit-gradient( linear, left top, left bottom, color-stop(0.05, #44b2e1), color-stop(1, #0590d1) );
  background: -moz-linear-gradient( center top, #44b2e1 5%, #0590d1 100% );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#44b2e1', endColorstr='#0590d1');
}
.reports-section-samples .blue-circle:hover {
  background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #0590d1), color-stop(1, #44b2e1) );
  background:-moz-linear-gradient( center top, #0590d1 5%, #44b2e1 100% );
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#0590d1', endColorstr='#44b2e1');
  background-color: #0590d1;
}
