/**
* General Layout
*/
html, body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  color: #222;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 17px;
}

.content-section {
  padding-top: 50px;
  padding-bottom: 50px;
  overflow-x: auto;
}

.page-divider {
  height: 40px;
}

@media(max-width: 768px) {
  .vehicle-history-report-container {
    zoom: 50%;
  }
}
