
/*******************************************************************************
 * index.html
 */

.verify-section .flex-row-item {
  max-width: 200px;
}

.knowledge-section .flex-row-item {
  max-width: 150px;
}

.screen-description {
  width: 558px;
  height: 327px;
  display: inline-block;
  position: relative;
  margin: 150px;
}

.screen-description .screen-pointer,
.screen-description .screen-pointer-desc {
  position: absolute;
}


.screen-pointer-desc {
  width: 400px;
  height: 1px;
  text-align: left;
}
.screen-pointer-desc img {
  position: absolute;
  left: 0;
  top: 0;
}
.screen-pointer-desc .pointer-title {
  position: absolute;
  left: 50px;
  top: 0;
  font-weight: bold;
  font-size: 20px;
}
.screen-pointer-desc .pointer-text {
  position: absolute;
  left: 50px;
  top: 30px;
  font-size: 1.2em;
}


/*
 * screen pointers
 */

.easy-search-pointer {
  top: -6px;
  left: 25px;
}
.no-account-required-pointer {
  top: -6px;
  left: 435px;
}
.email-copies-pointer {
  top: 160px;
  left: 46px;
}
.affordable-reports-pointer {
  top: 114px;
  left: 380px;
}
.payment-options-pointer {
  top: 204px;
  left: 340px;
}

/*
 * screen pointer descriptions
 */

.easy-search-pointer-desc {
  top: -110px;
  left: -250px;
}
.no-account-required-pointer-desc {
  top: -110px;
  left: 490px;
  width: 350px;
}
.email-copies-pointer-desc {
  top: 150px;
  left: -250px;
  width: 310px;
}
.affordable-reports-pointer-desc {
  top: 154px;
  left: 490px;
  width: 350px;
}
.payment-options-pointer-desc {
  top: 335px;
  left: 106px;
}
