/*******************************************************************************
 * Understanding Section
 */
.understanding-section .understanding-block {
  margin-top: 50px;
}
.understanding-section .understanding-block-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.understanding-section .understanding-block-header h3 {
  margin-top: 0;
}
.understanding-section .understanding-block-text {
  padding: 20px;
  border-style: solid;
  border-width: 3px;
  border-top-color: #0084bb;
  border-right-color: white;
  border-bottom-color: white;
  border-left-color: white;
}

@media(min-width: 992px) {
  .understanding-section .understanding-block > * {
    float: none;
    display: table-cell;
    vertical-align: middle;
  }
  .understanding-section .understanding-block-text {
    padding: 10px 20px;
    padding-bottom: 0;
    border-top-color: white;
    border-left-color: #0084bb;
  }
}