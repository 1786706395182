/**
 * Main Global Styles
 */
/**
 * Global Variables
 */
/**
* General Layout
*/
html,
body {
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  color: #222;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 17px;
}
.content-section {
  padding-top: 50px;
  padding-bottom: 50px;
  overflow-x: auto;
}
.page-divider {
  height: 40px;
}
@media (max-width: 768px) {
  .vehicle-history-report-container {
    zoom: 50%;
  }
}
/**
 * Text utilites
 */
.text-blue {
  color: #0084bb;
}
.text-gray {
  color: gray;
}
.text-header {
  margin-top: 1.5em;
  margin-bottom: .5em;
}
.text-note {
  float: right;
  padding-right: 5px;
  font-style: italic;
  font-size: 85%;
  color: #444444;
}
.in-text-link:link {
  text-decoration: none;
  color: #0084bb;
}
.in-text-link:visited {
  text-decoration: none;
  color: #0084bb;
}
.in-text-link:hover {
  text-decoration: underline;
}
.in-text-link:active {
  text-decoration: underline;
}
ul.list-blue {
  list-style-type: square;
}
ul.list-blue > li {
  color: #0084bb;
}
ul.list-blue > li > span {
  color: black;
}
@media (min-width: 992px) {
  h1.large {
    font-size: 50px;
  }
}
/**
* Navbar
*/
.navbar-container {
  padding: 0;
}
.navbar {
  background-color: #FFF;
  border-width: 0;
  font-size: 17px;
  margin-bottom: 0;
}
.navbar-header {
  padding-top: 5px;
}
.navbar-header .logo-container {
  display: inline-block;
  line-height: 0;
  height: 61px;
}
.navbar-header .logo {
  height: 100%;
  width: 100%;
}
.navbar-header .logo img {
  display: block;
  max-height: 100%;
  width: auto;
}
.navbar-header button {
  margin-top: 13px;
}
.navbar-default .navbar-nav > li a {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 71px;
}
.navbar-default .navbar-nav > li a:hover,
.navbar-default .navbar-nav > li a:focus {
  text-decoration: underline;
}
.navbar-default .navbar-nav > li > a:active,
.navbar-default .navbar-nav > li > a:link,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:visited {
  color: #000;
}
.navbar-default .navbar-menubuilder[aria-expanded="true"] .navbar-nav > li {
  border-top: 1px solid #444;
}
.navbar-default .navbar-menubuilder[aria-expanded="true"] .navbar-nav > li > a:active,
.navbar-default .navbar-menubuilder[aria-expanded="true"] .navbar-nav > li > a:link,
.navbar-default .navbar-menubuilder[aria-expanded="true"] .navbar-nav > li > a:hover,
.navbar-default .navbar-menubuilder[aria-expanded="true"] .navbar-nav > li > a:focus,
.navbar-default .navbar-menubuilder[aria-expanded="true"] .navbar-nav > li > a:visited {
  color: #fff;
}
.navbar-default .navbar-toggle {
  border-color: #0084BB;
}
.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle.nav-shown,
.navbar-default .navbar-toggle .icon-bar {
  background-color: #0084BB;
}
.navbar-default .navbar-toggle.nav-shown .icon-bar,
.navbar-default .navbar-toggle:focus .icon-bar,
.navbar-default .navbar-toggle:hover .icon-bar {
  background-color: #FFF;
}
.navbar-menubuilder.collapsing,
.navbar-menubuilder[aria-expanded="true"] {
  padding: 0;
}
.collapsing .navbar-nav,
.navbar-menubuilder[aria-expanded="true"] .navbar-nav {
  padding: 0;
  margin: 0;
  width: 100%;
}
.collapsing .navbar-nav li,
.navbar-menubuilder[aria-expanded="true"] li {
  width: 100%;
  background-color: #222;
  color: #FFF;
}
.collapsing .navbar-nav li:hover,
.navbar-menubuilder[aria-expanded="true"] li:hover {
  background-color: #171717;
}
.collapsing .nav.navbar-nav a,
.collapsing .nav.navbar-nav a:visited,
.navbar-menubuilder[aria-expanded="true"] .navbar-nav a,
.navbar-menubuilder[aria-expanded="true"] .navbar-nav a:visited {
  color: #FFF;
}
/* Resizing logo and logo links correctly */
@media (min-width: 992px) {
  /* Large */
  .navbar {
    font-size: 20px;
  }
  .navbar-header .logo-container {
    height: 81px;
  }
  .navbar-nav > li > a {
    line-height: 93px;
  }
}
.inpage-nav .inpage-nav-links h4 {
  margin-bottom: 1em;
}
.inpage-nav .inpage-nav-section {
  margin-bottom: 4em;
}
/**
* Footer links
*/
.footer-links {
  background: #222;
  color: #FFF;
  padding-top: 20px;
  padding-bottom: 20px;
}
.footer-links .flex-row-item {
  width: 160px;
  margin: 20px;
}
.footer-links .footer-logos {
  margin-top: 30px;
}
.footer-links .footer-logos small {
  font-size: 60%;
}
.footer-links .footer-logos img {
  margin: 10px;
}
.footer-links ul {
  list-style: none;
  padding: 0;
  line-height: 2em;
}
.footer-links a {
  cursor: pointer;
}
.footer-links a,
.footer-links a:visited {
  color: #FFF;
  text-decoration: none;
}
.footer-links a:hover,
.footer-links a:focus {
  color: #FFF;
  text-decoration: underline;
}
.footer-cap {
  color: #fff;
  background: #0084bb;
  padding: 20px;
  font-size: 12px;
}
.footer-cap a {
  display: inline-block;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
}
/**
 * Miscellaneous utilities
 */
.flex-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.flex-row-item {
  max-width: 200px;
  margin: 10px;
}
.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/*******************************************************************************
 * Search Section
 */
.search-section {
  color: #FFF;
  background: #0084bb;
  padding-top: 30px;
  padding-bottom: 30px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.41);
}
.search-section p {
  font-size: 20px;
}
.search-section .search-title {
  display: block;
  font-size: 50px;
  max-width: none;
}
.search-section .search-form {
  display: block;
  width: 100%;
  margin-top: 1em;
}
.search-section .search-form .input-group {
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-section .search-form button {
  display: inline-block;
  float: right;
}
.search-section .input-field-group {
  color: #000;
}
.search-section .search-form-index button {
  margin-top: 15px;
}
.search-section .tablet-image {
  position: relative;
  min-height: 442px;
  pointer-events: none;
}
.search-section .tablet-image img {
  position: absolute;
  top: 0;
}
@media (min-width: 768px) {
  /* Small Devices */
  .search-section .search-section-inner {
    /**
       * Using flex to make the search box look nice with long title names. Even without the support it looks decent,
       * the VIN search just isn't vertically centered and instead goes to the top.
       */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .search-section .search-form {
    display: inline-block;
    float: right;
    margin-top: 0;
    max-width: 388px;
    vertical-align: middle;
  }
  .search-section .search-title {
    display: inline-block;
    max-width: 330px;
  }
}
@media (min-width: 992px) {
  /* Medium devices */
  .search-section .search-form {
    max-width: 432px;
  }
  .search-section .search-title {
    max-width: none;
  }
}
@media (min-width: 1200px) {
  /* Large devices */
  .search-section .search-form-index {
    margin-top: 50px;
  }
}
/*******************************************************************************
 * Info Bubbles
 */
.info-bubble-container .flex-row-item {
  max-width: 330px;
}
.info-bubble-container .info-bubble {
  display: inline-block;
  position: relative;
  margin: 50px 20px;
  padding: 30px;
  background: #E0E0E0;
  border-radius: 20px;
  text-align: left;
  white-space: normal;
}
.info-bubble-container .info-bubble *:first-child {
  margin-top: 0;
}
.info-bubble-container .info-bubble *:last-child {
  margin-bottom: 0;
}
.info-bubble-container .info-bubble:after {
  position: absolute;
  bottom: -20px;
  left: 43%;
  content: '';
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #E0E0E0;
}
.info-bubble-container .info-bubble-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
/*******************************************************************************
 * Fancy standard button
 */
.ui-state-default.tc-button,
.tc-button {
  display: inline-block;
  padding: 0.3em 1.5em;
  border: 1px solid #459EDA;
  border-radius: 3px;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-indent: 0;
  text-decoration: none;
  text-align: center;
  text-shadow: 1px 1px #3880B0;
  background: linear-gradient(to top, #44b2e1 5%, #0590d1 100%);
  box-shadow: inset 0 0 0 -50px #dcecfb;
}
.tc-button:hover {
  background: linear-gradient(to top, #0590d1 5%, #44b2e1 100%);
}
.tc-button-inverted {
  display: inline-block;
  padding: 0.3em 1.5em;
  border: 1px solid #ffffff;
  border-radius: 3px;
  font-family: sans-serif;
  font-size: 16px;
}
.tc-button-inverted:hover {
  background: linear-gradient(to top, #dcecfb 5%, #dcecfb 100%);
}
/*******************************************************************************
 * index.html
 */
.verify-section .flex-row-item {
  max-width: 200px;
}
.knowledge-section .flex-row-item {
  max-width: 150px;
}
.screen-description {
  width: 558px;
  height: 327px;
  display: inline-block;
  position: relative;
  margin: 150px;
}
.screen-description .screen-pointer,
.screen-description .screen-pointer-desc {
  position: absolute;
}
.screen-pointer-desc {
  width: 400px;
  height: 1px;
  text-align: left;
}
.screen-pointer-desc img {
  position: absolute;
  left: 0;
  top: 0;
}
.screen-pointer-desc .pointer-title {
  position: absolute;
  left: 50px;
  top: 0;
  font-weight: bold;
  font-size: 20px;
}
.screen-pointer-desc .pointer-text {
  position: absolute;
  left: 50px;
  top: 30px;
  font-size: 1.2em;
}
/*
 * screen pointers
 */
.easy-search-pointer {
  top: -6px;
  left: 25px;
}
.no-account-required-pointer {
  top: -6px;
  left: 435px;
}
.email-copies-pointer {
  top: 160px;
  left: 46px;
}
.affordable-reports-pointer {
  top: 114px;
  left: 380px;
}
.payment-options-pointer {
  top: 204px;
  left: 340px;
}
/*
 * screen pointer descriptions
 */
.easy-search-pointer-desc {
  top: -110px;
  left: -250px;
}
.no-account-required-pointer-desc {
  top: -110px;
  left: 490px;
  width: 350px;
}
.email-copies-pointer-desc {
  top: 150px;
  left: -250px;
  width: 310px;
}
.affordable-reports-pointer-desc {
  top: 154px;
  left: 490px;
  width: 350px;
}
.payment-options-pointer-desc {
  top: 335px;
  left: 106px;
}
/*******************************************************************************
 * Understanding Section
 */
.understanding-section .understanding-block {
  margin-top: 50px;
}
.understanding-section .understanding-block-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.understanding-section .understanding-block-header h3 {
  margin-top: 0;
}
.understanding-section .understanding-block-text {
  padding: 20px;
  border-style: solid;
  border-width: 3px;
  border-top-color: #0084bb;
  border-right-color: white;
  border-bottom-color: white;
  border-left-color: white;
}
@media (min-width: 992px) {
  .understanding-section .understanding-block > * {
    float: none;
    display: table-cell;
    vertical-align: middle;
  }
  .understanding-section .understanding-block-text {
    padding: 10px 20px;
    padding-bottom: 0;
    border-top-color: white;
    border-left-color: #0084bb;
  }
}
/*******************************************************************************
 * reports.html
 */
.reports-section-samples .blue-circle {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
  width: 212px;
  height: 212px;
  text-align: center;
  border: 1px solid #459EDA;
  border-radius: 50%;
  background-color: #1081b6;
  -moz-box-shadow: inset 0 0 0 -50px #dcecfb;
  -webkit-box-shadow: inset 0 0 0 -50px #dcecfb;
  box-shadow: inset 0 0 0 -50px #dcecfb;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #44b2e1), color-stop(1, #0590d1));
  background: -moz-linear-gradient(center top, #44b2e1 5%, #0590d1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#44b2e1', endColorstr='#0590d1');
}
.reports-section-samples .blue-circle:hover {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #0590d1), color-stop(1, #44b2e1));
  background: -moz-linear-gradient(center top, #0590d1 5%, #44b2e1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0590d1', endColorstr='#44b2e1');
  background-color: #0590d1;
}
/**
 * Record Search application page
 */
.tc-header {
  margin-bottom: 25px;
  color: #0084bb;
  font-weight: bold;
}
.tc-prompt {
  margin-bottom: 25px;
}
.state-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: -5px;
  margin-right: -5px;
  max-height: 34em;
}
.state-list span {
  margin-left: 5px;
  margin-right: 5px;
}
@media (min-width: 768px) {
  .state-list {
    max-height: 17em;
  }
}
@media (min-width: 1200px) {
  .state-list {
    max-height: 11em;
  }
}
