
/**
* Footer links
*/
.footer-links {
  background: #222;
  color: #FFF;
  padding-top: 20px;
  padding-bottom: 20px;
}
.footer-links .flex-row-item {
  width: 160px;
  margin: 20px;
}
.footer-links .footer-logos {
  margin-top: 30px;

}
.footer-links .footer-logos small {
  font-size: 60%;
}
.footer-links .footer-logos img {
  margin: 10px;
}
.footer-links ul {
  list-style: none;
  padding: 0;
  line-height: 2em;
}
.footer-links a {
  cursor: pointer;
}
.footer-links a,
.footer-links a:visited {
  color: #FFF;
  text-decoration: none;
}
.footer-links a:hover,
.footer-links a:focus {
  color: #FFF;
  text-decoration: underline;
}

.footer-cap {
  color: #fff;
  background: #0084bb;
  padding: 20px;
  font-size: 12px;
}
.footer-cap a {
  display: inline-block;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
}