/**
 * Miscellaneous utilities
 */

.flex-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.flex-row-item {
  max-width: 200px;
  margin: 10px;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
