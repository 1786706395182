@state-margin: 5px;

.state-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: -@state-margin;
  margin-right: -@state-margin;
  max-height: 34em;

  span {
    margin-left: @state-margin;
    margin-right: @state-margin;
  }
}

@media (min-width: @screen-sm-min) {
  .state-list {
    max-height: 17em;
  }
}
@media (min-width: @screen-lg-min) {
  .state-list {
    max-height: 11em;
  }
}
