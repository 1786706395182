/*******************************************************************************
 * Fancy standard button
 */
.ui-state-default.tc-button,
.tc-button {
  display: inline-block;
  padding: 0.3em 1.5em;
  border: 1px solid #459EDA;
  border-radius: 3px;

  color: #ffffff;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-indent: 0;
  text-decoration: none;
  text-align: center;
  text-shadow: 1px 1px #3880B0;

  background: linear-gradient(to top, #44b2e1 5%, #0590d1 100%);
  box-shadow: inset 0 0 0 -50px #dcecfb;
}

.tc-button:hover {
  background: linear-gradient(to top, #0590d1 5%, #44b2e1 100%);
}

.tc-button-inverted {
  display: inline-block;
  padding: 0.3em 1.5em;
  border: 1px solid #ffffff;
  border-radius: 3px;

  font-family: sans-serif;
  font-size: 16px;
}

.tc-button-inverted:hover {
  background: linear-gradient(to top, #dcecfb 5%, #dcecfb 100%);
}